import { Status } from './status';

export class Giro {
    id: number;
    descripcion: string;
    ramo: string;
    status: Status;
    createdAt: string;
    updatedAt: string;
}

