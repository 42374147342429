export class MatchCreateDireccion {

  tipoDireccionId: number;
  origenDatosId: number;
  procesoId: number;
  numeroExterior: string;
  numeroInterior: string;
  emailFacturacion: string;
  manzana: string;
  lote: string;
  edificio: string;
  referenciaEntreCalle: string;
  referenciaYCalle: String;
  latitud: string;
  longitud: string;
  latitudFromPoint: string;
  longitudFromPoint: string;
  streetId: number;
  direccionGoogle: string;
  vendedorId: number;

  direccionCompleta: string;
  cuentaContrato: string;
  instalacion: string;
  objetoConexion: string;
  puntoSuministro: string;
  fechaBaja: string;

  tipoDepositoGarantia: string;
  depositoGarantia: string;
}
