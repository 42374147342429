export class MatchUpdateCliente {
    rfc: string;
    businessPartner: string;
    tipoClienteId: number;
    tratamientoId: number;
    giroId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    motherLastName: string;
    email: string;
    contact: string;
    phone: string;
    phoneMobile: string;
    homoclave: string;
    fechaNacimiento: string;
}