import { Material } from '../dto/material';
import { TipoDocumento } from '../dto/tipo-documento';

export class MatchUpdateContratoBorrador {

	naceConGas: boolean;
	giroContrato: number;
	tipoCliente: number;
	acometida: boolean;
	cfdiId: number;
	elementoPepId: number;
	citygateId: number;
	elementoEstrRegionalPolId: number;
	estructuraRegionalId: number;
	aparatosAConectar: Array<number>;
	otrosAparatos: string;
	instalacionGarantia: boolean;
	renta: boolean;
	folio: any;
	tipoInmueble: number;
	tipoPES: number;
	fechaFirma: string;
	contratoSustituyeA: string;
	tipoFinanciamiento: number;
	prioridad: number;
	tamanioComercio: number;
	metodoPago: number;
	determinanteCuenta: string;
	formaPago: number;
	condicionPagoId: number;
	costoEspecial: number;
	materiales: Array<Material>;
	tipoDocumentos: Array<TipoDocumento>;
	descuentoId: number;

	// costo, descuento contrato
	eTagP: number;
	eTagD: number;
	instalacionPresupuestoId: number;
	tipoPresupuestoEspecialId: number;

	precio: string;
	adicionalMAT: string;
	adicionalAPA: string;
	adiciconalesT: string;
	descuentoTotal: string;
	costoFinal: string;

	regimenFiscalId: number;

	aceptoServi2: boolean;
    finesMercadotecnicos: boolean;
    envioPublicidad: boolean;
    registroMiCuenta: boolean;
    requiereFactura:boolean;
	idMotivoTipoContrato: number;
  caracteristicasDelInmueble: number[];
  comentariosDelInmueble?: string;
}
