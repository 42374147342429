import { Injectable } from '@angular/core';
import { ConfigFilterUser } from '../model/dto/config-filter-user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ConfigFilterUserService {

	constructor(protected http: HttpClient) {

	}

	getUri(): string {
	  return environment.API_BASE_URL + "config-filter-user";
  }

  protected getHeader(): HttpHeaders {
    return new HttpHeaders({
      'jwt': localStorage.getItem("eTag")
    });
  }

  getById(id: number): Observable<ConfigFilterUser[]> {
    return this.http.get<ConfigFilterUser[]>(`${this.getUri()}/cliente/busqueda`, { headers: this.getHeader() });
  }

  getByIdTipoConfigFilter(idTipoConfigFilter: number): Observable<any> {
    return this.http.get<any>(`${this.getUri()}/cliente/busqueda/${idTipoConfigFilter}`, { headers: this.getHeader() });
  }
}
