
export class StatusContrato {
   public static REVALIDACION = 4;
   public static EN_PROCESO = 1;
   public static VALIDACION_JEFE_DE_VENTAS = 2;
   public static REVALIDACION_JEFE_DE_VENTAS = 4;
   public static VALIDACION_ADM_VENTAS = 3;
   public static CORRECCION_AL_CONTRATO_CAPTURADO = 5;
   public static RECHAZO_AL_CONTRATO_CAPTURADO = 6;
   public static EXITOSO = 8;
   public static CANCELADO = 10;
}
/* (1, 'En Proceso', 1);
 (2, 'Validación Jefe Ventas', 1);
 (3, 'Validación Adm. Ventas', 1);
 (4, 'Re validación Jefe de Ventas', 1);
 (5, 'Correcciones al contrato capturado', 1);
 (6, 'Rechazo al contrato capturado', 1);
 (7, 'Pendiente de aviso SAP', 1);
 (8, 'Exitoso', 1);
 (9, 'Perdido', 1);*/
