export const ConstantStatusMeterSAP = {
  EN_SAP: 1,
  EN_PROCESO_CERTIFICACION: 2,
  MEDIDOR_REUTILIZABLE: 3,
  MEDIDOR_SIN_VIDA_UTIL: 4,
  MEDIDOR_RECXREMO: 5,
  MEDIDOR_NUEVO_SAP: 6,
  MEDIDOR_DISPONIBLE_SAP: 7,
  MEDIDOR_INSTALADO_SAP: 8,
  EN_OYM: 9,
  CONSUMO_INTERNO: 10,
  EN_SAP_STRING: 'EN SAP',
  EN_PROCESO_CERTIFICACION_STRING: 'EN PROCESO CERTIFICACION'
};
