
export class TipoComentario {
	public static CONTRATO = 1;
	public static NEGOCIO = 2;
	public static CLIENTE = 3;
	public static SERVICIO = 4;
	public static PRESUPUESTO = 5;
	public static COSTO = 6;
	public static FOLIOS = 7;
  public static LEAD = 8;

}
