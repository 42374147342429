export class ConstantTipoHistorial {
  public static CLIENTE = 1;
  public static NEGOCIO = 2;
  public static CONTRATO = 3;
  public static SERVICIO = 4;
  public static PRESUPUESTO = 5;
  public static DIRECCION_CLIENTE = 6;
  public static OBJETIVO_SUPERVISOR = 7;
  public static MEDIDORES = 8;
  public static BAJA_DESMONTAJE = 9;
}
