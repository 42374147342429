export class MatchBusquedaCombinacion {
	regional: number;
	instaladora: number;
	tipoCliente: number;
	tipoInmueble: number;
	tipoPes: number;
	naceConGas: boolean;
	garantia: boolean;
	tamanoComercio: number;
	metodoPago: number;
	formaPago: number;
	instalacionPresupuestoId: number;
    tipoPresupuestoEspecialId: number;
}