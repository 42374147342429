export class MatchBusquedaPresupuesto {
	regional: number;
	instaladora: number;
	tipoCliente: number;
	tipoPes: number;
	tipoInmueble: number;
	naceConGas: boolean;
	tamanoComercio: number;
	tipoComercio: number;
	garantia: boolean;
	metodoPago: number;
	formaPago: number;
	tipoFinanciamiento: number;
	instalacionPresupuestoId: number;
    tipoPresupuestoEspecialId: number;
}